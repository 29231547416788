<template>
  <div class="section o-grid u-ai-center">
    <div
      :class="textClasses"
      class="section__text o-grid__col u-6/12@md section--mob"
    >
      <div class="section__small-text u-color-accent">
        {{ upperText }}
      </div>
      <slot />
    </div>
    <div
      v-if="imageName"
      :class="imageClasses"
      class="section__image o-grid__col u-text-center@sm u-5/12@md u-mb-x4@sm"
    >
      <img :src="require(`@/assets/home/${imageName}`)">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Section',
  props: {
    textIsLeft: {
      type: Boolean
    },
    upperText: {
      type: String
    },
    imageName: {
      type: String
    },
    customClass: {
      type: String
    }
  },
  computed: {
    textClasses () {
      return {
        'u-push-1/12@md': !this.textIsLeft,
        'section--order-2': !this.textIsLeft,
        [this.customClass]: !!this.customClass
      }
    },
    imageClasses () {
      return {
        'u-push-1/12@md': this.textIsLeft
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  &--order-2 {
    order: 2;
  }
  &--mob {
    @media (max-width: 768px) {
      order: 2;
    }
  }
  &__text {
    max-width: 515px;
  }
  &__small-text {
    font-weight: bold;
  }
}
</style>
