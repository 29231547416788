<template>
  <button
    class="btn u-bgcolor-white u-flex u-ai-center u-jc-between"
    v-on="$listeners"
  >
    <span
      :class="disabledColor"
      class="btn__title u-color-primary"
    >{{ title }}</span>
    <span
      :class="disabledBgColor"
      class="btn__icon u-bgcolor-secondary"
    >
      <img :src="iconSRC">
    </span>
  </button>
</template>

<script>
export default {
  name: 'RkButton',
  props: {
    title: String,
    'iconName': {
      type: String,
      default: function () {
        return 'arrow-right'
      }
    }
  },
  computed: {
    disabledColor () {
      return ('disabled' in this.$attrs && this.$attrs.disabled) && ['btn--color-disabled']
    },
    disabledBgColor () {
      return ('disabled' in this.$attrs && this.$attrs.disabled) && ['btn--bgcolor-disabled']
    },
    iconSRC () {
      return require(`@/assets/${this.iconName || ('disabled' in this.$attrs && this.$attrs.disabled) ? this.iconName + '.svg' : 'arrow-right.svg'}`)
    }
  }
}
</script>

<style lang="scss" scoped>
$white: #ffffff;
$grey: #9b9b9b;
$rose: #ffcccc;
.btn {
  font-family: inherit;
  font-size: 1.125rem;
  font-weight: bold;
  padding: 0;
  border-radius: 3px;
  cursor: pointer;
  border: 0;
  transition: all 0.3s ease;
  &:hover,
  &:active {
    background-color: $rose !important;
    box-shadow: 0 0 10px rgba(255, 204, 204, 0.4);
  }
  &:active,
  &:focus {
    outline: 0;
  }
  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
    background-color: $white !important;
  }
  &__title {
    padding: 0.65rem 1.3rem;
    white-space: nowrap;
  }
  &__icon {
    min-width: 50px;
    position: relative;
    min-height: 50px;
    border-radius: 0 3px 3px 0;
    img {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }
  &--color-disabled {
    color: $grey !important;
  }
  &--bgcolor-disabled {
    background-color: $grey !important;
  }
}
</style>
