<template>
  <div class="cta-button u-bgcolor-primary u-color-white u-flex u-jc-center u-ai-center">
    {{ label }}
  </div>
</template>

<script>
export default {
  name: 'RkCtaButton',
  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #6545e6;
.cta-button {
  box-shadow: 0 -5px 8px rgba(0, 0, 0, 0.14);
  font-size: 1rem;
  font-weight: bold;
  min-height: 4.625rem;
  white-space: nowrap;
}
</style>
