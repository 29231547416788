<template>
  <div class="footer-contact u-flex u-fd-col@sm u-ai-center@sm u-pb-x6@sm u-12/12@md u-jc-center u-pv-x10@md">
    <span class="footer-contact__text u-text-center@sm u-mb-x2@sm u-mr-x2@md">Tell us about your project</span>
    <router-link
      class="footer-contact__button"
      to="/contact"
    >
      <rk-button title="Contact us" />
    </router-link>
  </div>
</template>

<script>
import rkButton from '@/components/Button'

export default {
  name: 'RkFooterContact',
  components: {
    rkButton
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.footer-contact {
  &__text {
    font-size: 1.875rem;
    font-weight: bold;
    @media screen and (max-width: 768px){
      font-size: 1.5rem;
      max-width: 300px;
    }
  }
}
</style>
