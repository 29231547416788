<template>
  <section
    :class="classes(isDark)"
    class="project__one-image u-pt-x3 u-pb-x6"
  >
    <div class="o-container">
      <div class="o-grid">
        <div class="o-grid__col u-4/12">
          <project-description
            :data="project"
            @tag-click="(tagTitle) => $emit('tag-click', tagTitle)"
          />
          <rk-button
            v-if="project.buttonTitle"
            :title="project.buttonTitle"
            class="u-mt-x2"
            @click="openWindow(project.buttonLink)"
          />
        </div>
        <div class="o-grid__col u-8/12">
          <img
            :src="require(`@/assets/projects/${project.image[0]}`)"
            class="project__one-image-image"
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import projectSections from '@/mixins/project-sections'
export default {
  name: 'OneImage',
  mixins: [projectSections]
}
</script>

<style lang="scss" scoped>
$purple: #4f38be;
.project__one-image {
  &--dark {
    background-color: $purple;
    padding: 50px 0;
    margin-bottom: 50px;
  }
}
</style>
