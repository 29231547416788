import rkButton from '@/components/Button'
import ProjectDescription from '@/components/Project/Project-Description'
export default {
  components: {
    ProjectDescription,
    rkButton
  },
  props: {
    project: {
      type: [Object, Array],
      required: true
    },
    isDark: {
      type: Number,
      required: true
    }
  },
  methods: {
    classes (index) {
      const isDark = (index + 1) % 2 === 0 && (index + 1) !== this.$parent.dataProjects.length
      return {
        'project__one-image--dark': isDark && this.project.image.length === 1,
        'project__two-images--dark': isDark && this.project.image.length === 2,
        'project__three-images--dark': isDark && this.project.image.length === 3
      }
    },
    openWindow (link) {
      window.open(link, '_blank')
    }
  }
}
