<template>
  <div class="member u-relative">
    <div
      :class="isLeft"
      class="member__card u-relative@sm"
    >
      <img
        :src="require(`@/assets/${imageSrc}`)"
        class="member__card-image u-absolute@md"
      >
      <rk-gradient class="member__card-text u-relative u-absolute@sm u-6/12@sm">
        <slot name="text" />
      </rk-gradient>
    </div>
    <slot name="description" />
    <div
      v-if="socialIcons"
      class="member__social u-flex u-ai-end u-mt-x2"
    >
      <a
        v-for="icon in socialIcons"
        :key="icon.src"
        :href="icon.url"
        target="_blank"
        class="member__social-button u-flex@sm u-ai-center@sm u-jc-center@sm u-mr-x2"
      >
        <img
          :src="require(`@/assets/${icon.src}`)"
          alt=""
        >
      </a>
    </div>
  </div>
</template>

<script>
import rkGradient from '@/components/About/Gradient'
export default {
  name: 'RkCard',
  components: {
    rkGradient
  },
  props: {
    position: {
      type: String,
      required: true,
      default: 'left',
      validator: function (value) {
        return value === 'left' || value === 'right'
      }
    },
    imageSrc: {
      type: String,
      required: true
    },
    socialIcons: {
      type: [Array, Object]
    }
  },
  computed: {
    isLeft () {
      return {
        'member__card--right': this.position === 'right'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.member {
  &__card {
    &--right {
      @media screen and (max-width: 768px){
        min-height: 185px;
      }
      .member__card {
        &-image {
          left: auto;
          right: -63%;
          @media screen and (max-width: 768px){
            position: absolute;
            right: 0;
          }
        }
        &-text {
          left: -30px;
          @media screen and (max-width: 768px){
            left: 0;
          }
        }
      }
    }
    &-image {
      position: static;
      left: -90%;
      top: -18%;
      max-width: 448px;
      @media screen and (max-width: 768px){
        max-width: 220px;
        left: 0;
        top: 0;
      }
    }
    &-text {
      @media screen and (max-width: 768px){
        font-size: 1rem;
        transform: translateY(-50%);
        top: 50%;
        text-align: right;
        padding: .5rem;
        padding-left: 2rem;
        left: auto;
        right: 0;
        min-width: 180px;
      }
    }
  }
  &__social-button {
    @media screen and (max-width: 768px){
      background-color: rgba(216, 216, 216, 0.2);
      border-radius: 2px;
      height: 58px;
      width: 58px;
    }
  }
  p {
    margin-top: 1.875rem;
    @media screen and (max-width: 768px){
      font-size: 1rem;
    }
  }
}
</style>
