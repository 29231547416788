import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/views/Index'
import ProjectsPage from '@/views/ProjectsPage'
import ContactPage from '@/views/ContactPage'
import ContactSuccess from '@/views/ContactSuccess'
import Services from '@/views/Services'
import AboutUs from '@/views/AboutUs'
import Meta from 'vue-meta'

import VueAnalytics from 'vue-analytics'

// Vue.use(Router)

Vue.use(Router)
Vue.use(Meta)

const scrollBehavior = async function (to, from, savedPosition) {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition
  } else {
    const position = {}
    let delay = 600
    // new navigation.
    // scroll to anchor by returning the selector
    if (to.hash) {
      position.selector = to.hash
      if (document.querySelector(to.hash)) {
        delay = 0
      }
    }
    // check if any matched route config has meta that requires scrolling to top
    if (to.matched.some(m => m.meta.scrollToTop)) {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      position.x = 0
      position.y = 0
    }
    // wait for the out transition to complete (if necessary)
    await (new Promise(resolve => setTimeout(resolve, delay)))
    // if the returned position is falsy or an empty object,
    // will retain current scroll position.
    if (to.hash) {
      return window.scroll({
        behavior: 'smooth',
        left: 0,
        top: document.getElementById(to.hash.substr(1)).offsetTop
      })
    } else {
      return window.scroll({
        behavior: 'smooth',
        left: 0,
        top: 0
      })
    }
  }
}

const router = new Router({
  mode: 'history',
  routes: [{
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/projects/:tag?',
    name: 'ProjectsPage',
    component: ProjectsPage
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage
  },
  {
    path: '/contact-success',
    name: 'ContactSuccess',
    component: ContactSuccess
  },
  {
    path: '/about',
    name: 'About',
    component: AboutUs
  },
  {
    path: '*',
    redirect: '/'
  }],
  scrollBehavior
})
export default router

Vue.use(VueAnalytics, {
  id: 'UA-77058842-1',
  router
})
