<template>
  <div class="card">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'RkGradient'
}
</script>

<style lang="scss" scoped>
.card {
  font-size: 1.875rem;
  font-weight: bold;
  z-index: 1;
  left: 10px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(101, 69, 230, 0.8)), to(rgba(0, 204, 255, 0.8)));
  background: linear-gradient(90deg, rgba(101, 69, 230, 0.8) 0%, rgba(0, 204, 255, 0.8) 100%);
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  max-width: 330px;
}
</style>
