<template>
  <div
    id="about"
    class="about-us u-pt-x20@md u-pt-x6"
  >
    <div class="o-container o-container--custom">
      <div class="u-text-center u-mb-x4@sm u-mb-x8@md">
        <h1 class="about-us__title">
          heart + mind = rakun design
        </h1>
      </div>
      <div class="about-us__team o-grid">
        <div class="about-us__member o-grid__col u-4/12@md u-push-2/12@md u-mt-x10@md">
          <rk-card
            :social-icons="larryIcons"
            position="left"
            image-src="larry-photo-logo@2x.png"
          >
            <span
              slot="text"
              v-html="larryText"
            />
            <template slot="description">
              <p class="u-relative u-8/12@lg u-12/12@md">
                Born in Russia and raised in Greece, Larry is a citizen of the world and Rakun’s co-founder. He’s currently based in London, UK. Along his journey he has become fluent in Greek, Russian, English, HTML, CSS, & Javascript.
              </p>
              <p class="u-relative u-8/12@lg u-12/12@md">
                Larry got his first computer at 8 years old, disassembled it entirely and reassembled it again. Since then he’s been using computers to connect with and create meaningful experiences for users around the world.
              </p>
            </template>
          </rk-card>
        </div>
        <div class="about-us__member o-grid__col u-4/12@md u-push-1/12@md u-mt-x6@sm u-mt-x10@md">
          <rk-card
            :social-icons="mikeIcons"
            position="right"
            image-src="mike-photo-logo@2.png"
          >
            <span
              slot="text"
              v-html="mikeText"
            />
            <template slot="description">
              <p class="u-relative u-9/12@lg u-12/12@md">
                Originally from Greece, Rakun’s co-founder Mike now resides in Dublin. Mike loves spending his days improving processes, solving complex problems and creating effective solutions that make the web a better place.
              </p>
              <p class="u-relative u-9/12@lg u-12/12@md">
                Mike’s childhood dream was to become a psychologist. Now he uses his love of human behaviour to make people happy through user experience based design.
              </p>
            </template>
          </rk-card>
        </div>
      </div>
    </div>
    <div class="about-us__text u-flex u-jc-center u-mt-x10@sm u-mb-x6@sm u-mt-x14@md">
      <h1>…and the cream of the creative crop</h1>
    </div>
    <div class="about-us__testimonial u-12/12@md u-mt-x14@md">
      <div class="about-us__logos u-flex u-relative o-container ">
        <img
          v-for="(img, index) in 6"
          :key="img"
          :style="logosPosition(index)"
          class="about-us__logo u-3/12@md u-absolute"
          src="@/assets/logo.png"
        >
        <rk-gradient class="about-us__opinion u-absolute u-hidden@sm">
          "Rakun did a wonderful job helping us translate our<br> mission. They rocked our visual identity and<br> promptly delivered a great web experience."<br><br> Maria Arnaoutaki, Co-Founder FPower
        </rk-gradient>
      </div>
      <div class="o-container o-container--custom">
        <div class="o-grid">
          <div class="o-grid__col about-us__quote-left u-4/12@md u-push-1/12@md">
            rakun brings you the best talent in the hood
          </div>
          <div class="o-grid__col about-us__quote-right u-mt-x1@sm u-mb-x6@sm u-5/12@md u-push-1/12@md">
            We work with an ever expanding team of creative professionals we trust  by our side. We call ourselves Rakuns because we believe in extreme adaptability & outside-the-box agility. Our focus is on creating solutions, exceeding expectations, and delivering measurable results.
          </div>
          <rk-footer-contact class="o-grid__col u-12/12@sm" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rkCard from '@/components/About/Card'
import rkGradient from '@/components/About/Gradient'
import rkFooterContact from '@/components/Footer-Contact'
import isMobile from '@/mixins/isMobile'
const mapLogoPosition = [-20, 15, 50, 85, 120, 155]
export default {
  name: 'About',
  metaInfo: {
    title: 'About Us',
    meta: [
      {
        vmid: 'og:title',
        property: 'og:title',
        content: 'About Us - rakun.ie | Digital Design Duo'
      },
      {
        property: 'og:url',
        content: 'https://rakun.ie/about'
      },
      {
        property: 'og:image',
        content: 'https://rakun.ie/img/thumbnail/about.png'
      },
      {
        name: 'description',
        content:
          'We call ourselves Rakuns because we believe in extreme adaptability & outside-the-box agility. Our focus is on creating solutions, exceeding expectations, and delivering measurable results.'
      },
      {
        name: 'keywords',
        content:
          'team, rakun, professionals, digital, design, development, wordpress'
      }
    ]
  },
  components: {
    rkCard,
    rkGradient,
    rkFooterContact
  },
  mixins: [isMobile],
  data () {
    return {
      larryIcons: [
        { url: 'https://www.linkedin.com/in/larryioannidis/', src: 'linkedin.svg' },
        { url: 'https://twitter.com/@larry_ths', src: 'twitter.svg' }
      ],
      mikeIcons: [
        { url: 'https://www.linkedin.com/in/michailmarkoglou/', src: 'linkedin.svg' },
        { url: 'https://twitter.com/M1KEMARK', src: 'twitter.svg' }
      ]
    }
  },
  computed: {
    larryText () {
      return this.isMobile
        ? '"my name is Larry.<br> I am a developer & <br> a growth hacker."'
        : 'My name is Larry. I am <br>a developer & <br> a growth hacker'
    },
    mikeText () {
      return this.isMobile
        ? 'my name is Mike.<br>I am a designer &<br>a solutions architect'
        : 'My name is Mike.<br>I am a designer &<br>a solutions architect'
    }
  },
  methods: {
    logosPosition (index) {
      return this.isMobile
        ? { left: mapLogoPosition[index] + '%' }
        : { left: (index - 1) * 25 + '%' }
    }
  }
}
</script>

<style lang="scss" scoped>
$purple: #4f38be;
.about-us {
  &__title{
    @media screen and (max-width: 768px){
      font-size: 1.875rem;
    }
  }
  &__testimonial{
    background-color: rgba(0, 0, 0, 0.2);
    @media screen and (max-width: 768px){
      border-radius: 17px 17px 0 0;
    }
  }
  &__logos {
    min-height: 230px;
    transform: translateY(-50%);
    @media screen and (max-width: 768px){
      min-height: 100px;
      z-index: -1;
    }
  }
  &__logo {
    padding: 0 .5rem;
    max-height: 230px;
    max-width: 295px;
    @media screen and (max-width: 768px){
      padding: 0;
      max-height: 100px;
      max-width: 123px;
    }
  }
  &__text h1 {
    @media screen and (max-width: 768px){
      font-size: 1.875rem;
      max-width: 250px;
    }
  }
  &__opinion {
    font-size: 1rem;
    max-width: none;
    left: .5rem;
    transform: translateY(-50%);
    top: 50%;
  }
  &__quote-left{
    font-size: 1.875rem;
    font-weight: bold;
    @media screen and (max-width: 768px){
      font-size: 1.5rem;
      max-width: 300px;
    }
  }
  &__quote-right {
    font-size: 1.125rem;
    @media screen and (max-width: 768px){
      font-size: 1rem;
    }
  }
}
</style>
