<template>
  <component
    class="u-pt-x20@md u-pt-x6"
    :is="renderProject"
    :data-projects="filteredProjects"
  />
</template>

<script>
import Project from '@/components/Project/Project'
import ProjectMobile from '@/components/Project/Project-Mobile'
import isMobile from '@/mixins/isMobile'
import preferences from '@/components/Project/preferences'
export default {
  name: 'ProjectsPage',
  components: {
    Project,
    ProjectMobile
  },
  mixins: [isMobile],
  data () {
    return {
      projects: preferences.projects
    }
  },
  computed: {
    renderProject () {
      return this.isMobile ? ProjectMobile : Project
    },
    filteredProjects () {
      const params = this.$route.params && this.$route.params.tag
      return params
        ? this.projects.filter(project => {
          return project.tags.some(tag => tag.title === params)
        })
        : this.projects
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
