import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/scss/main.scss'
import VeeValidate from 'vee-validate'
import { VuePlausible } from 'vue-plausible'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

Sentry.init({
  dsn: 'https://665feee8cc974fb793859dcf810d1790@sentry.io/1527607',
  integrations: [new Integrations.Vue({ Vue, attachProps: true })]
})

var VueScrollTo = require('vue-scrollto')

Vue.use(VeeValidate)
Vue.use(VueScrollTo)

// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 100,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.config.productionTip = false

Vue.use(VuePlausible, {
  apiHost: 'https://analytics.rakun.ie',
  domain: 'rakun.ie',
  enableAutoOutboundTracking: true,
  enableAutoPageviews: true,
  trackLocalhost: true
})

new Vue({
  router,
  store,
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
  render: h => h(App)
}).$mount('#app')
