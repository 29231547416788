<template>
  <div class="tag u-inline-block">
    <div class="tag__link u-inline-block u-color-white">
      {{ `#${title}` }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'RkTags',
  props: {
    title: {
      type: String,
      required: true
    },
    link: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.tag {
  &__link {
    font-size: 1.125rem;
    font-weight: bold;
    text-decoration: none;
    margin-right: 4px;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
