<template>
  <footer :class="bgcolor === 'dark' ? 'u-bgcolor-dark-footer' : ''">
    <div class="footer__bgk c-footer_min-height o-grid__col" />
    <div class="u-bgcolor-white">
      <div class="o-container">
        <div class="o-grid u-pb-x3">
          <div class="o-grid__col u-6/12 u-flex u-fd-col u-jc-end">
            <div class="footer__copyright u-mb-x2">
              <b>Rakun Ltd<br>Copyright {{ year }}</b>
            </div>
            <div class="u-mv-x2">
              <a
                class="u-color-primary"
                href="mailto:info@rakun.ie"
              ><b>info@rakun.ie</b></a>
            </div>
          </div>
          <div class="o-grid__col u-6/12 u-flex u-fd-col u-ai-end u-jc-between">
            <router-link
              class="u-mv-x1 u-color-primary"
              to="/"
            >
              <b>rakun.ie</b>
            </router-link>
            <router-link
              class="u-mv-x1 u-color-primary"
              to="/projects"
            >
              <b>projects</b>
            </router-link>
            <router-link
              class="u-mv-x1 u-color-primary"
              to="/services"
            >
              <b>services</b>
            </router-link>
            <router-link
              class="u-mv-x1 u-color-primary"
              to="/about"
            >
              <b>about</b>
            </router-link>
            <router-link
              class="primary-action u-mv-x1 u-color-primary"
              to="/contact"
            >
              <b>contact</b>
            </router-link>
          </div>
        </div>
      </div>
      <router-link
        to="/contact"
      >
        <rk-cta-button
          v-if="!$route.path.includes('contact')"
          label="Contact us"
        />
      </router-link>
    </div>
  </footer>
</template>

<script>
import dimension from '@/mixins/dimension'
import rkCtaButton from '@/components/Footer/Contact-Button'
export default {
  name: 'FooterMobile',
  components: {
    rkCtaButton
  },
  props: {
    'bgcolor': {
      type: String,
      required: true,
      default: function () {
        return 'primary'
      }
    }
  },
  computed: {
    year () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  &.u-bgcolor-dark-footer {
    background-color: rgba(#000, 0.35);
  }
}
.footer__copyright {
  color: #979797;
}
.footer__bgk {
  background-image: url("~@/assets/footer-mobile.png");
  background-size: 100%;
}
.c-footer_min-height {
  min-height: calc(100vw / 6);
}
a {
  font-size: 1.125rem;
  text-decoration: none;
}
</style>
