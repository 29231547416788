<template>
  <section
    :class="{animate: animate}"
    class="welcome u-relative u-flex u-ai-center"
  >
    <div class="welcome__container o-container u-ai-center u-jc-center u-flex">
      <div class="o-grid u-12/12@md">
        <div class="welcome__text o-grid__col u-6/12@md">
          <p class="welcome__subtitle u-color-white u-12/12@md u-mb-x2">
            Hi, this is Rakun!
          </p>
          <h1 class="welcome__title u-color-white u-mb-x2">
            We help small and medium companies create digital experiences that sell.
          </h1>
          <router-link
            class="welcome__button"
            to="#home-what-we-do"
          >
            <rk-button
              icon-name="arrow-down"
              title="See how we do it"
            />
          </router-link>
        </div>
        <div class="o-grid__col u-push-1/12@md u-5/12@md u-mb-x6@sm u-flex u-ai-center u-jc-center">
          <div class="u-relative u-flex u-ai-center u-jc-center">
            <img
              src="@/assets/logo.svg"
              class="logo"
            >
            <div
              v-for="n in wavesNumber"
              :key="n"
              class="waves_container"
            >
              <rk-wave
                ref="wave"
                :i="n"
                :speed="speed"
                :number="wavesNumber"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="o-grid u-absolute welcome__logos">
      <div
        v-for="logo in logos"
        :key="logo.key"
        class="o-grid__col u-4/12@sm u-2/12 u-ph u-pv-x1 u-text-center u-flex u-ai-center u-jc-center "
      >
        <img
          :src="require(`@/assets/home/logos/${logo}.png`)"
          :alt="logo"
          style="opacity: 95%"
        >
      </div>
    </div>
  </section>
</template>

<script>
import rkWave from '@/components/Wave'
import rkButton from '@/components/Button'

export default {
  name: 'Welcome',
  components: {
    rkWave,
    rkButton
  },
  props: {
    wavesNumber: {
      type: Number,
      required: true,
      default: 4
    },
    speed: {
      type: Number,
      required: true,
      default: 3500
    }
  },
  data: () => ({
    animate: false,
    logos: [ 'pressidium', 'mybodymoon', 'manual', 'krataion', 'gen', 'learnsignal' ]
  }),
  mounted () {
    window.setTimeout(
      function () {
        this.animate = true
      }.bind(this),
      100
    )
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.welcome {
  height: 100vh;
  img {
    @media (max-width: 768px) {
      max-width: 150px;
    }
  }
  &__logos {
    bottom: 0;
    width: 100%;
    margin: 0 auto;
    img {
      max-width: 100%;
    }
    &:before {
      content: '';
      position: absolute;
      background: rgba(#000, 0.08);
      height: 100vh;
      width: 100%;
      bottom: 100%;
      z-index: -1;
    }
  }
  &__container {
    width: 100vw;
  }
  &__text {
    @media (max-width: 768px) {
      order: 2;
    }
  }
  &__title {
    letter-spacing: 0;
    @media (max-width: 768px) {
      font-size: 1rem;
      font-weight: 400;
    }
  }
  &__subtitle {
    font-size: 1.5rem;
    font-weight: bold;
    max-width: 400px;
    @media (max-width: 768px) {
      font-size: 30px;
      font-weight: bold;
      max-width: 250px;
    }
  }
  &__button {
    @media (max-width: 768px) {
      bottom: 5vh;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .waves_container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all ease 0.4s;
    transition-delay: 0.5s;
  }
  &.animate {
    .logo,
    .waves_container {
      opacity: 1;
    }
  }
}
</style>
