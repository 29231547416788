import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeSlider: 1,
    projects: [
      {
        name: 'FPower',
        url: 'https://fpower.gr',
        img: './img/build/fpower.png',
        tags: '#brand #design #development'
      },
      // {
      //   name: 'CYFX',
      //   url: 'http://CYFX.ie',
      //   img: './img/build/cyfx.png',
      //   tags: '#brand #design #development #motion'
      // },
      {
        name: 'Origins Foster Care',
        url: 'https://originsfostercare.ie',
        img: './img/build/origins.png',
        tags: '#brand #design #development'
      },
      {
        name: 'Krataion Consulting',
        url: 'https://krataionconsulting.com/',
        img: './img/build/krataion.png',
        tags: '#brand #design #development'
      },
      {
        name: 'Brian + Anthony',
        url: 'https://brianandanthony.com',
        img: './img/build/brian-anthony.png',
        tags: '#design #development'
      },
      {
        name: 'YourQuest',
        url: 'https://yourquest.ie',
        img: './img/yourquest.png',
        tags: '#design #develop #SEO'
      }
    ]
  },
  mutations: {
    activeTab (state, n) {
      state.activeSlider = n
    }
  },
  actions: {}
})
