<template>
  <div id="services" class="u-pt-x20@md u-pt-x6">
    <div class="o-container">
      <div class="o-grid">
        <div class="o-grid__col u-5/12@md">
          <h4 class="u-color-accent">
            Wonderful ideas with a pinch of stardust
          </h4>
          <h1 class="services__title u-text-left">
            we devise, design, develop and advise
          </h1>
        </div>
        <div class="o-grid__col u-push-1/12@md u-6/12@md u-mt-x2@sm">
          <p>
            Understanding how your company works and breathes is core to our
            holistic approach. We engage with you from the get-go to collaborate
            throughout projects - matching expertise with your vision and needs.
          </p>
          <br>
          <p>Your goals are our goals. Let’s make a dazzling impact.</p>
        </div>
      </div>
    </div>
    <div class="o-container u-mt-x8">
      <div class="o-grid o-grid--equal-height">
        <card
          v-for="card in services"
          :key="card.key"
          :img-link="card.imgLink"
          :title="card.title"
          :card-text="card.cardText"
          class="o-grid__col u-4/12@md u-mb"
        />
      </div>
    </div>
    <div class="o-container u-mt-x8 u-mb-x10">
      <div class="u-flex u-fd-col@sm u-ai-center u-jc-center">
        <div class="services-question u-pr-x4@md u-mb-x2@sm">
          Got a question?
        </div>
        <rk-button
          title="Contact us"
          @click="$router.push('contact')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import card from '../components/card.vue'
import rkButton from '@/components/Button'

export default {
  name: 'Services',
  metaInfo: {
    title: 'Our Services',
    meta: [
      {
        vmid: 'og:title',
        property: 'og:title',
        content: 'Our Services - rakun.ie | Digital Design Duo'
      },
      {
        property: 'og:url',
        content: 'https://rakun.ie/services'
      },
      {
        property: 'og:image',
        content: 'https://rakun.ie/img/thumbnail/services.png'
      },
      {
        name: 'description',
        content:
          'Check out our services. Design, Develop, Brand, SEO, Motion, Consulting'
      },
      {
        name: 'keywords',
        content:
          'team, rakun, professionals, digital, design, development, wordpress, contact, Design, Develop, Brand, SEO, Motion, Consulting'
      }
    ]
  },
  components: {
    card,
    rkButton
  },
  data: () => ({
    services: [
      {
        title: 'design',
        cardText:
          'State-of-the-art websites that strengthen your brand & scale revenue for your company. Perfectly crafted to achieve your goals & inspire your clients.',
        imgLink: 'design'
      },
      {
        title: 'develop',
        cardText:
          'Clean lightweight code, for efficient & reliable performance. Host your sites on the fastest servers & ensure exceptional performance 24/7.',
        imgLink: 'develop'
      },
      {
        title: 'brand',
        cardText:
          'Our creators can set the foundations for a strong timeless identity that will help boost your business perspectives and potential.',
        imgLink: 'brand'
      },
      {
        title: 'SEO',
        cardText:
          'Search Engine Optimisation for cleaning up your website code, increased organic traffic & better performance in Google results.',
        imgLink: 'seo'
      },
      {
        title: 'motion',
        cardText:
          'Brilliant videos to showcase your valuable services, tell your vibrant stories and achieve your biggest goals.',
        imgLink: 'motion'
      },
      {
        title: 'advice',
        cardText:
          'Strong partnerships that offer field expertise with a common goal in mind: identifying the key factors to scale your company growth.',
        imgLink: 'advice'
      }
    ]
  }),
  mounted () {
    // do something after mounting vue instance
    // console.log(this.$route.path);
  }
}
</script>
<style lang="scss" scoped>
.services {
  margin-top: 6rem;
  display: flex;
  flex-wrap: wrap;
  &__title {
    @media screen and (max-width: 768px) {
      font-size: 1.875rem;
    }
  }
  .columns {
    margin: 1.796875rem 0;
  }
  .card {
    height: 100%;
  }
  &-question {
    font-size: 1.875rem;
    font-weight: bold;
  }
}
</style>
