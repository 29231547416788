<template>
  <div>
    <div
      class="card"
      @mouseover="active = true"
      @mouseleave="active = false"
    >
      <div class="img-container">
        <img
          v-if="imgLink"
          :src="require('@/assets/services/' + imgLink + '.svg')"
          alt=""
        >
      </div>
      <h3>{{ title }}</h3>
      <p>{{ cardText }}</p>
      <transition
        v-for="n in wavesNumber"
        :key="n.key"
        name="fade"
      >
        <rk-wave
          v-if="active"
          ref="wave"
          :i="n"
          :speed="1500"
          :number="wavesNumber"
          class="wave"
        />
      </transition>
    </div>
  </div>
</template>
<script>
import rkWave from '@/components/Wave'
export default {
  components: {
    rkWave
  },
  props: ['title', 'cardText', 'imgLink'],
  data: () => ({
    active: false,
    wavesNumber: 4
  })
}
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  padding: 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: box-shadow ease 0.2s;
  overflow: hidden;
  z-index: 9;
  height: 100%;
  .img-container {
    height: 6.25rem;
    margin: 84px 0 64px;
    img {
      display: block;
      margin: 0 auto;
      transform: scale(0.8);
      transition: transform ease 0.1s;
    }
  }
  &:hover {
    // box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    img {
      transform: scale(1.1);
    }
  }
  .wave {
    position: absolute;
    top: -80%;
    left: -50%;
    width: 200%;
    height: 200%;
  }
}

p {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

h3 {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  line-height: 38px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
/* .fade-leave-active below version 2.1.8 */
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
