<template>
  <div class="home">
    <home-welcome
      :waves-number="4"
      :speed="3500"
    />
    <main
      id="home-what-we-do"
      class="home__what-we-do u-mt-x10"
    >
      <div class="o-container">
        <home-section
          class="u-mb-x14@md u-mb-x10@sm"
          text-is-left
          image-name="home-chat.png"
          upper-text="An extension of your team."
        >
          <h1 class="u-mb-x1">
            we join your team and understand your goals
          </h1>
          <p class="u-mb-x1">
            We engage as closely as needed with our Clients, creating a constant flow of feedback and cooperation.
          </p>
          <p>We start with your Business Goals. We will get a good understanding of your Competitors Landscape, Target Market and Business Case. Together we will explore the most efficient ways to meet your customers' needs.</p>
        </home-section>
        <div class="section o-grid u-ai-center u-mt-x14@md u-mt-x10@sm">
          <div class="section__image o-grid__col u-text-center@sm u-5/12@md u-mb-x6@sm">
            <div class="c-card-shift">
              <img
                src="@/assets/home/cards/card-1.png"
                alt=""
              >
              <img
                src="@/assets/home/cards/card-3.png"
                alt=""
              >
              <img
                src="@/assets/home/cards/card-2.png"
                alt=""
              >
            </div>
          </div>
          <div class="section__text o-grid__col u-6/12@md section--mob">
            <div class="section__small-text u-color-accent">
              A creative boost.
            </div>
            <h1 class="u-mb-x1">
              we sketch, we design, we prototype, we review
            </h1>
            <p class="u-mb-x1">
              We usually start with wireframes that look like simple skeletons of your product without the visual polish of the finished design, allowing us to experiment and iterate on solutions quickly based on your feedback.
            </p>
            <p>Next we design high-fidelity compositions that show what the final designs will look like, usually providing up to two rounds of revisions on the final design.</p>
          </div>
        </div>
        <home-section
          class="u-mt-x14@md u-mt-x10@sm"
          text-is-left
          image-name="home-laptop.png"
          upper-text="Results, results, results."
        >
          <h1 class="u-mb-x1">
            we develop, we deliver
          </h1>
          <p class="u-mb-x1">
            Once you’ve approved the design, we’ll start building. Either it's a Web Application, a Landing Page or a Blog, we will use the best tools out there to create a fast, secure and successful product for you.
          </p>
          <p>If you need hosting and/or constant maintenance, we got you covered there, as well.</p>
          <rk-gradient class="home__quote u-hidden@sm u-mv">
            "Rakun did a wonderful job helping us translate our mission. They rocked our visual identity and promptly delivered a great web experience. "<br>
            <br>Maria Arnaoutaki, Co-Founder FPower
          </rk-gradient>
        </home-section>
      </div>
    </main>
    <footer class="home__footer u-mt-x10@sm u-pt-x14@md">
      <div class="o-container">
        <home-section
          image-name="rakun-way@2x.png"
          upper-text="Reach for the stars with Rakun"
        >
          <h1 class="u-mb-x1">
            we advise, we innovate, we help you grow
          </h1>
          <p class="u-mb-x2">
            Our part doesn't end with the first project. We are putting all our effort to understand your product, suggest ways to innovate and maximise the value of our services for your business. This is the foundation of our high client
            retention.
          </p>
          <router-link
            class="u-decoration-none"
            :to="{ path: '/projects'}"
          >
            <rk-button title="Our projects" />
          </router-link>
        </home-section>
        <rk-footer-contact class="o-grid__col u-12/12@sm u-mt-x4@md u-mt-x6@sm" />
      </div>
    </footer>
  </div>
</template>

<script>
import homeWelcome from '@/components/Home/Welcome'
import homeSection from '@/components/Home/Section'
import rkGradient from '@/components/About/Gradient'
import rkButton from '@/components/Button'
import rkFooterContact from '@/components/Footer-Contact'

export default {
  components: {
    homeWelcome,
    homeSection,
    rkGradient,
    rkButton,
    rkFooterContact
  },
  data: () => ({

  }),
  metaInfo: {
    title: 'rakun | Digital Design Duo',
    meta: [{
      vmid: 'og:title',
      property: 'og:title',
      content: 'rakun.ie | Digital Design Duo'
    },
    {
      property: 'og:url',
      content: 'https://rakun.ie/'
    },
    {
      property: 'og:image',
      content: 'https://rakun.ie/img/thumbnail/home.png'
    },
    {
      name: 'description',
      content: 'We deliver unique, tailor made designs - that blow your mind. Every time. Our collaborations bring big company knowledge & experience to businesses of all sizes, globally.'
    },
    {
      name: 'keywords',
      content: 'team, rakun, professionals, digital, design, development, wordpress, contact, Design, Develop, Brand, SEO, Motion, Consulting'
    }
    ]
  }
}
</script>

<style lang="scss" scoped>
.home {
    h1 {
        @media (max-width: 768px) {
            font-size: 1.875rem;
        }
    }
    p {
        font-size: 1.125rem;
    }
    &__footer {
        background: url('~@/assets/home/footer-bg.png'), url("~@/assets/home/footer-bg-back.png");
        background-size: cover, cover;
        p {
            max-width: 430px;
        }
    }
    &__quote {
        font-size: 1rem;
        font-weight: bold;
        max-width: 380px;
    }
}

.chat-bot {
    position: fixed;
    z-index: 9;
    bottom: 1rem;
    right: 1rem;
    transform: scale(0.5);
    transform-origin: right bottom;
    @media (max-width: 768px) {
        transform: scale(0.25);
        opacity: 0.7;
        &:hover {
            opacity: 1;
        }
    }
}
.c-card-shift {
    position: relative;
    img {
        box-shadow: 0 4px 7px 0 rgba(0,0,0,0.20);
        transition: all ease 400ms;
        &:first-child {
            position: absolute;
            left: 0;
            z-index: 1;
            transform: rotate(-10deg) translate(-40px, 20px);
        }
        &:nth-child(2) {
            z-index: 999;
            position: relative;
        }
        &:last-child {
            position: absolute;
            left: 0;
            z-index: 1;
            transform: rotate(10deg) translate(40px, 20px);
        }
    }
    &:hover {
        img {
            box-shadow: 0 4px 10px 0 rgba(0,0,0,0.15);
            &:first-child {
                transform: rotate(-15deg) translate(-50px, 10px);
                transform-origin: bottom;
            }
            &:last-child {
                transform: rotate(20deg) translate(50px, 20px);
                transform-origin: bottom;
            }
        }
    }
}
</style>
