<template>
  <section
    :class="classes(isDark)"
    class="project__two-images u-pt-x3 u-pb-x14"
  >
    <div class="o-container">
      <div class="o-grid u-relative">
        <div class="o-grid__col u-flex u-fd-col u-jc-end u-ai-end u-6/12">
          <img :src="require(`@/assets/projects/${project.image[0]}`)">
          <rk-button
            v-if="project.buttonTitle"
            :title="project.buttonTitle"
            class="u-mt-x2"
            @click="openWindow(project.buttonLink)"
          />
        </div>
        <div class="o-grid__col u-4/12 u-push-1/12">
          <project-description
            :data="project"
            @tag-click="(tagTitle) => $emit('tag-click', tagTitle)"
          />
          <img
            :src="require(`@/assets/projects/${project.image[1]}`)"
            class="project__two-images-image u-absolute"
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import projectSections from '@/mixins/project-sections'
export default {
  name: 'TwoImages',
  mixins: [projectSections]
}
</script>

<style lang="scss" scoped>
section {
  overflow: hidden;
}
$purple: #4f38be;
.project__two-images {
  &-image {
    max-height: 300px;
  }
  &--dark {
    background-color: $purple;
    padding: 50px 0 180px;
    margin-bottom: 80px !important;
  }
}
</style>
