export default {
  data () {
    return {
      isMobile: false
    }
  },
  computed: {
    isCalcMobile () {
      return matchMedia('(max-width: 768px)').matches
    }
  },
  methods: {
    calcWidth () {
      this.isMobile = matchMedia('(max-width: 768px)').matches
    }
  },
  mounted () {
    const vm = this
    vm.isMobile = vm.isCalcMobile
    window.addEventListener('resize', vm.calcWidth, true)
  },
  beforeDestroy () {
    const vm = this
    window.removeEventListener('resize', vm.calcWidth, true)
  }
}
