<template>
  <header
    id="header"
    :style="isMenuSticky && !isMobile ? 'height: 112px' : ''"
  >
    <div
      class="u-mb-x6@md"
      :class="{ 'u-fixed u-12/12 u-bgcolor-dark-primary': isMenuSticky && !isMobile, 'u-relative' : !isMenuSticky }"
    >
      <div
        class="o-grid u-jc-between u-pv-x1@md"
        :class="{'u-pv-x3@md' : !isMenuSticky}"
      >
        <router-link
          id="logo"
          class="menu-item o-grid__col u-6/12"
          to="/"
        >
          <img
            class="top-logo u-ph u-ph-x1@sm"
            src="../assets/logo-header.svg"
            alt=""
            @click="open = false"
          >
        </router-link>
        <div
          v-if="isMobile"
          class="burger-container u-ph-x2@sm"
          @click="open = !open"
        >
          <span
            :class="{'u-hidden': open}"
            class="u-mr"
          >
            menu
          </span>
          <div
            class="burger"
            :class="{ open: open}"
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div
          v-if="!isMobile || open"
          id="menu"
          class="menu-item u-ph o-grid__col u-6/12 u-12/12@sm"
          @click="open = false"
        >
          <router-link to="/projects">
            projects
          </router-link>
          <router-link to="/services">
            services
          </router-link>
          <router-link to="/about">
            about
          </router-link>
          <router-link
            class="primary-action"
            to="/contact"
          >
            contact
          </router-link>
        </div>
      </div>
    </div>
    <div
      v-if="!isMenuSticky"
      class="u-absolute c-header_wave u-hidden@sm"
      style="top: -2px; right: 0px;"
    >
      <svg
        width="580px"
        height="172px"
        class="c-header_wave-1"
        viewBox="0 0 580 172"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g>
          <path
            d="M398,135.946047 C287.542008,129.671926 188.397009,155.236393 114.931319,135.946047 C48.2207915,118.429446 49.5264385,89.06782 26.8272392,2.00215323 C35.8278297,1.27936358 228.885417,0.9453125 606,1 C607.228137,99.4739583 607.842205,153.133563 607.842205,161.978815 C538.765445,193.667486 491.615641,141.263506 398,135.946047 Z"
          />
        </g>
      </svg>
    </div>
    <div
      v-if="!isMenuSticky"
      class="u-absolute c-header_wave u-hidden@sm"
      style="top: -2px; right: -59px;"
    >
      <svg
        width="606px"
        height="161px"
        viewBox="0 0 606 161"
        version="1.1"
        class="c-header_wave-2"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g>
          <path
            d="M372.019531,115.460937 C242.582251,122.3664 114.575518,170.326659 58.046875,136.554687 C13.2143461,109.770339 13.2143461,74 0.938833692,0.989647334 C45.5264862,1.74654911 247.82761,1.74807665 607.842205,0.994229947 C606.999485,67.2558891 606.385417,118.742187 606,155.453125 C520.399879,181.258761 501.456811,108.555475 372.019531,115.460937 Z"
          />
        </g>
      </svg>
    </div>
  </header>
</template>
<script>
import anime from 'animejs'

export default {
  props: ['isMobile'],
  data: () => ({
    open: false,
    isMenuSticky: false
  }),
  mounted () {
    // do something after mounting vue instance
    this.animateWaves()
    this.stickyMenu()
  },
  methods: {
    stickyMenu () {
      const content = document.getElementById('header')
      document.addEventListener('scroll', (e) => {
        var scrolled = document.scrollingElement.scrollTop
        var position = content.offsetTop

        if (scrolled > position + 172) {
          this.isMenuSticky = true
        } else {
          this.isMenuSticky = false
        }
      })
    },
    animateWaves () {
      anime({
        targets: '.c-header_wave-1 path',
        duration: 10000,
        easing: 'linear',
        d: [
          'M397.381088,114.851455 C286.923096,108.577334 187.397009,155.236393 113.931319,135.946047 C47.8111976,118.584472 28.1136039,72.9234023 14.056802,2.42849805 C42.2324757,2.42849805 239.213542,1.95233203 605,1 C605.476562,63.9583333 605.786669,109.15863 605.93032,136.600891 C545.567672,167.701782 494.79674,120.384758 397.381088,114.851455 Z',
          'M398,135.946047 C287.542008,129.671926 188.397009,155.236393 114.931319,135.946047 C48.2207915,118.429446 49.5264385,89.06782 26.8272392,2.00215323 C35.8278297,1.27936358 228.885417,0.9453125 606,1 C607.228137,99.4739583 607.842205,153.133563 607.842205,161.978815 C538.765445,193.667486 491.615641,141.263506 398,135.946047 Z'
        ],
        direction: 'alternate',
        loop: true
      })
      anime({
        targets: '.c-header_wave-2 path',
        duration: 10000,
        easing: 'linear',
        d: [
          'M370.884245,128.539118 C241.446965,135.44458 124.719393,181.453881 68.1907497,147.68191 C22.9957873,120.681032 15.525905,69.0715058 0.651624363,0.438096636 C31.2172081,0.765823879 232.976773,0.765823879 605.93032,0.438096636 C605.687711,66.3491572 605.484506,116.281869 605.320705,150.236232 C492,194 500.321525,121.633656 370.884245,128.539118 Z',
          'M372.019531,115.460937 C242.582251,122.3664 114.575518,170.326659 58.046875,136.554687 C13.2143461,109.770339 13.2143461,74 0.938833692,0.989647334 C45.5264862,1.74654911 247.82761,1.74807665 607.842205,0.994229947 C606.999485,67.2558891 606.385417,118.742187 606,155.453125 C520.399879,181.258761 501.456811,108.555475 372.019531,115.460937 Z'
        ],
        direction: 'alternate',
        loop: true
      })
    }
  }
}
</script>
<style lang="scss" scoped>
header {
  z-index: 99;
  position: absolute;
  width: 100%;
  user-select: none;
  > div {
    transition: all ease 400ms;
  }
  #logo {
    padding-left: 1.5rem;
  }

  .hidden {
    visibility: hidden;
  }
}
@media (max-width: 768px) {
  header {
    user-select: none;
    top: 0;
    position: fixed;
    width: 100%;
    // background-color: #6545e6;
    // border-bottom: 1px solid #877ce2;
    padding: 0.75rem 0;
    z-index: 99;
    #logo {
      z-index: 99;
      position: relative;
    }
    .top-logo {
      width: 124px;
    }
    #menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #6545e6;
      a {
        margin: 1rem 0;
        font-size: 2.5em;
      }
    }
  }
}
.burger-container {
  z-index: 99;
  color: #ffffff;
  font-size: 29px;
  font-weight: 500;
  line-height: 23px;
  cursor: pointer;
  .burger {
    display: inline-block;
    width: 22px;
    height: 14px;
    position: relative;
    span {
      background: #fff;
      border-radius: 2px;
      height: 2px;
      position: absolute;
      z-index: 1;
      transition: all ease 300ms;
      &:nth-child(1) {
        top: calc(0% - 2px);
        width: calc(100% - 3px);
      }
      &:nth-child(2) {
        top: calc(50% - 2px);
        width: 100%;
      }
      &:nth-child(3) {
        top: calc(100% - 2px);
        width: calc(100% - 3px);
      }
    }
    &.open {
      span {
        &:nth-child(1) {
          transform: rotateZ(45deg);
          transform-origin: 5%;
          width: 100%;
        }
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3) {
          transform: rotateZ(-45deg);
          transform-origin: 5%;
          width: 100%;
        }
      }
    }
  }
}
#menu {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  a {
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    padding: 9px 23px;
    font-size: 20px;
    line-height: 1em;
    &.router-link-active {
      position: relative;
      &::after {
        content: "..";
        font-size: 40px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .primary-action {
    transform: translateY(-1px);
    border: 1px solid #ffffff;
    border-radius: 100px;
    transition: all ease 0.4s;
    &:after {
      display: none;
    }
    &:hover {
      background: #fff;
      color: #6545e6;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
  // transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
}
.fade-leave-active {
  transition-delay: 0.25s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: all 0.4s ease;
  // transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transition-delay: 0.25s;
}
.c-header {
  &_wave {
    svg {
      path {
        fill: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
</style>
