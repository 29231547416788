<template>
  <div class="project-description">
    <h1 class="project-description__title u-color-white u-mv-x1">
      {{ title || data.title }}
    </h1>
    <p class="project-description__text u-color-white u-mv-x1">
      {{ text || data.text }}
    </p>
    <div
      v-if="filteredTags.length > 0"
      class="project-section__tags u-mv-x1"
    >
      <rk-tags
        v-for="tag in filteredTags"
        :key="tag.title"
        :title="tag.title"
        :link="tag.link"
        class="project-description__tag"
        @click.native="$emit('tag-click', tag.title)"
      />
    </div>
  </div>
</template>

<script>
import rkTags from '@/components/Tags'
export default {
  name: 'ProjectDescription',
  components: {
    rkTags
  },
  props: {
    data: [Object, Array],
    title: {
      type: String
    },
    text: {
      type: String
    },
    tags: {
      type: [Object, Array]
    }
  },
  computed: {
    filteredTags () {
      return this.tags || this.data.tags
    }
  }
}
</script>

<style lang="scss" scoped>
.project-description {
  &__title {
    font-weight: bold;
    white-space: nowrap;
    @media screen and (max-width: 768px){
      font-size: 1.875rem;
    }
  }
  &__text {
    font-size: 1.125rem;
    line-height: 1.5rem;
    @media screen and (max-width: 768px){
      font-size: 1rem;
    }
  }
}
</style>
