<template lang="html">
  <svg
    :class="className"
    width="100%"
    height="100%"
    viewBox="420 400 1297 970"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g stroke="#FFCCCC">
        <g
          id="4"
          transform="translate(994.194243, 979.925402) rotate(25.000000) translate(-994.194243, -979.925402) translate(236.694243, 251.925402)"
        >
          <path
            d="M778.72863,519.950272 C790.384317,518.896367 804.80978,523.710261 819.203571,532.203401 C828.832603,537.885067 838.447461,545.213279 847.209449,553.532827 C854.680939,560.627041 861.532285,568.442081 867.243469,576.571697 C872.773812,584.443893 877.235055,592.611058 880.155031,600.704325 C885.770767,616.269406 885.68574,631.561155 876.541191,643.955635 C869.982073,652.850275 853.327814,656.944722 836.866378,660.97369 C822.582903,664.469598 808.444602,667.916209 801.172383,674.40661 C787.032908,687.021321 783.085238,695.427775 774.05503,699.509835 C767.782055,702.345501 759.056449,703.094387 742.757977,701.71756 C722.360123,699.994435 707.46108,691.611666 697.51273,678.745449 C688.10766,666.581856 683.127332,650.411126 682.108611,632.072041 C681.422975,619.729187 682.531855,606.40412 685.294055,592.657427 C688.006088,579.143247 692.187287,568.683269 697.628786,560.401165 C702.664532,552.736625 708.779634,546.937278 715.808551,542.308576 C723.765636,537.068652 732.893809,533.328953 742.952908,530.081853 C753.881918,526.553944 765.909829,523.607518 778.72863,519.950272 Z"
            opacity="0"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import anime from 'animejs'

export default {
  name: 'RkWave',
  props: {
    i: {
      type: Number,
      default: 1
    },
    number: {
      type: Number,
      default: 1
    },
    speed: {
      type: Number,
      default: 3500
    }
  },
  computed: {
    className: function className () {
      return 'a' + this.i + ' waves'
    }
  },
  mounted () {
    // do something after mounting vue instance
    setTimeout(() => {
      this.animate()
    }, (this.$props.i - 1) * this.$props.speed)
  },
  methods: {
    animate () {
      anime({
        targets: '.a' + this.i + ' path',
        duration: this.number * this.speed,
        easing: 'linear',
        d: [
          'M816.760996,414.244485 C884.351581,392.31354 877.768518,430.739948 934.720537,449.356681 C959.220492,457.356475 979.435261,466.654198 995.408035,481.164321 C1010.98352,495.313537 1022.52541,514.419082 1030.07375,542.110541 C1041.55215,584.225184 1040.09615,611.057524 1030.14361,634.254393 C1019.74515,658.490601 1000.0717,678.758265 976.184784,708.340922 C952.10691,738.163418 935.691909,760.41275 916.716475,777.718496 C894.624253,797.866778 869.061399,811.314093 823.894197,822.210263 C786.824246,831.154209 764.147145,833.354139 741.229933,824.629816 C721.793554,817.230608 702.184465,801.973638 673.475925,776.308781 C650.039865,755.319041 642.866021,739.659699 623.628946,715.294109 C599.246801,684.392548 556.130816,676.548165 559.336995,632.895336 C560.606525,615.605359 561.335226,601.682625 561.571066,587.916189 C561.852724,571.475285 561.431419,555.257306 560.388855,533.792778 C558.566764,496.30277 610.990635,497.440369 644.294849,488.288565 C677.599063,479.136761 694.233615,478.272829 728.971428,463.382671 C770.062382,445.769277 771.793446,428.834992 816.760996,414.244485 Z',
          'M754.153221,315.504976 C804.930297,304.758713 853.68879,324.250006 899.586511,350.707054 C938.347032,373.049972 975.067318,400.360702 1009.24014,418.623198 C1043.09139,436.711621 1072.89224,451.026767 1094.84802,472.610043 C1115.48435,492.896259 1129.19018,519.603288 1132.81468,561.899148 C1137.52649,617.049548 1120.66215,655.590918 1094.35619,689.265871 C1071.17033,718.946696 1040.64973,744.847038 1011.10302,775.007188 C989.045614,797.524741 971.506182,818.806095 952.43295,836.92199 C928.381764,859.765981 901.891683,877.576575 860.828268,886.485401 C834.96363,892.095355 799.199588,903.550657 762.423741,912.327933 C715.618345,923.498958 667.17401,930.332067 635.413213,915.255665 C590.63387,894.005895 567.730299,831.807325 535.418862,796.093721 C510.996951,769.095729 490.563602,749.204313 481.646276,713.694753 C468.940966,663.184752 470.535012,607.566338 487.232822,558.187362 C499.975734,520.503858 521.515018,486.454178 552.208195,461.081927 C584.808694,434.123657 616.779154,399.812801 651.57767,370.91389 C682.685968,345.079572 716.054321,323.570237 754.153221,315.504976 Z',
          'M941.865919,295.428109 C970.028322,315.753656 1010.5761,313.812879 1041.86116,327.854283 C1082.82997,346.244898 1106.285,383.575625 1141.49385,410.36653 C1179.48395,439.279363 1200.76804,467.223627 1209.93755,497.539328 C1223.09119,541.027147 1211.3156,589.394874 1188.16414,652.501834 C1166.93123,710.333479 1173.88412,781.984391 1133.78076,830.620728 C1100.04553,871.540724 1047.78486,893.128944 1005.13191,923.094072 C979.624357,941.022876 956.520193,964.206976 929.585802,979.9261 C877.87414,1010.10686 825.472111,1009.60243 772.117633,1002.77417 C737.631135,998.360621 702.746714,991.305047 667.393599,988.186093 C565.50371,979.195386 471.432163,993.838898 460.730918,867.898844 C457.587071,830.881165 440.275594,800.823123 422.211584,771.407788 C399.149505,733.853623 374.860851,697.347025 377.261205,648.74304 C381.658657,559.835582 435.311074,469.110107 470.958501,389.222584 C498.155226,328.278674 538.846793,303.994952 587.225929,287.721466 C624.051121,275.334426 665.330442,267.588467 708.502754,251.848314 C784.253536,224.262392 883.014918,234.691145 941.865919,295.428109 Z',
          'M744.001903,163.759107 C811.586728,169.729202 878.967957,164.644637 943.963554,172.618705 C988.556612,178.089662 1032.02669,189.707635 1073.66909,215.260276 C1134.5488,252.633738 1255.16231,323.243011 1280.12691,395.151518 C1295.74879,440.21504 1274.01731,495.637321 1286.90302,544.61025 C1299.01699,590.627715 1328.07415,638.646703 1316.35774,686.554494 C1303.77605,738.014696 1261.54086,786.566253 1238.86224,834.140457 C1213.90934,886.484372 1203.59609,936.49903 1159.7765,977.524393 C1122.82655,1012.14137 1077.92535,1034.74336 1030.56861,1050.94867 C973.085723,1070.5952 924.623997,1102.3562 869.292622,1127.33427 C810.014541,1154.09696 761.333801,1153.08533 714.570455,1141.91732 C655.534535,1127.81839 599.55447,1097.53268 529.166029,1086.50789 C487.129619,1079.92393 439.860582,1083.45879 402.431222,1059.33517 C340.388053,1019.35154 340.458211,938.447881 345.264043,872.731219 C352.963897,767.780031 279.712967,689.018357 266.698635,585.442484 C252.262866,470.749464 307.801734,375.145791 392.717214,304.027288 C491.38042,221.394775 629.702322,171.817464 744.001903,163.759107 Z',
          'M779.332834,82.8892819 C825.484553,79.9999854 871.157713,102.044031 914.928312,113.261987 C994.211456,133.574794 1083.00741,125.228588 1158.94065,155.016416 C1257.24941,193.606655 1293.08294,285.765854 1357.0347,359.787992 C1394.4323,403.068951 1417.94587,440.278878 1418.11511,500.35168 C1418.34272,579.491499 1410.85503,639.687125 1424.79159,717.692281 C1448.17676,848.541149 1300.03371,983.753205 1202.65288,1047.32358 C1088.26572,1121.98323 977.199284,1267.23764 833.363261,1270.70012 C770.959203,1272.20828 704.842937,1273.94036 639.34334,1270.5816 C576.0588,1267.33642 513.349924,1259.3389 455.121092,1241.79545 C372.972432,1217.04342 291.710857,1133.634 270.193231,1049.86195 C249.305901,968.563928 256.034901,885.546369 223.813905,806.371457 C204.502317,758.926168 163.999923,722.14905 145.248598,676.388209 C128.411508,635.300541 145.890567,578.391929 155.397541,536.941637 C175.076802,451.145909 207.175241,372.09967 265.658597,305.60491 C327.690289,235.068644 406.150546,160.713126 494.958173,125.708188 C574.836597,94.2242142 693.180624,88.2818555 779.332834,82.8892819 Z'
        ],
        opacity: [0.6, 0],
        loop: true
      })
    }
  }
}
</script>
<style media="screen" lang="scss" scoped>
// @media (max-width: 768px) {
//   svg {
//     width: 150%;
//   }
// }
svg:not(:root) {
  overflow: visible;
}
</style>
