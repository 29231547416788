<template>
  <div class="projects">
    <div class="u-flex u-jc-center">
      <h3 class="projects__title u-12/12@sm u-text-center">
        it’s not what you say, it’s what you do
      </h3>
    </div>
    <div class="projects__container">
      <project-section
        v-for="(project, index) in dataProjects"
        :key="project.title"
        :title="project.title"
        :text="project.text"
        :tags="project.tags"
        :button-title="project.buttonTitle"
        :button-link="project.buttonLink"
        :dark-background="isDark(index)"
        class="u-ph-x1@sm u-pv-x6"
        @tag-click="filterProjects"
      >
        <div
          slot="top"
          class="u-text-center"
        >
          <img :src="require(`@/assets/projects/${project.imageMobile[0]}`)">
        </div>
        <div
          slot="bottom"
          class="u-text-right u-mt-x4 minus-margin"
        >
          <img
            v-if="project.imageMobile.length > 1"
            :src="require(`@/assets/projects/${project.imageMobile[1]}`)"
          >
        </div>
      </project-section>
      <div class="u-flex u-fd-col u-jc-center u-mb-x6">
        <h3 class="projects__title-secondary u-12/12@sm u-text-center u-mv-x2">
          Tell us about your project
        </h3>
        <router-link
          class="projects__contact"
          to="/contact"
        >
          <rk-button title="Contact us" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectSection from '@/components/Project/Project-Section'
import rkButton from '@/components/Button'
export default {
  name: 'ProjectMobile',
  components: {
    ProjectSection,
    rkButton
  },
  props: {
    dataProjects: [Array],
    required: true
  },
  methods: {
    filterProjects (tag) {
      this.$router.push({ name: 'ProjectsPage', params: { tag } })
    },
    isDark (index) {
      return (index + 1) % 2 === 0 && (index + 1) !== this.dataProjects.length
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.minus-margin {
  margin-right: -10%;
}
.projects {
  &__title {
    font-size: 1.875rem;
    font-weight: bold;
    max-width: 350px;
    &-secondary {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
  &__contact {
    align-self: center;
  }
}
</style>
