<template>
  <div class="projects">
    <div class="u-text-center u-mb-x3">
      <h1 class="projects__title">
        it’s not what you say, it’s what you do
      </h1>
    </div>
    <template v-for="(project, index) in dataProjects">
      <component
        :is="renderSection(project.image)"
        :key="index"
        :project="project"
        :is-dark="index"
        @tag-click="filterProjects"
      />
    </template>
    <div class="projects__contact u-flex u-relative u-jc-center u-ai-center u-pv-x14">
      <h3 class="projects__contact-title u-mr-x2">
        Let’s talk about your project.
      </h3>
      <router-link to="/contact">
        <rk-button title="Contact us" />
      </router-link>
    </div>
  </div>
</template>

<script>
import OneImage from '@/components/Project/One-Image'
import TwoImages from '@/components/Project/Two-Images'
import ThreeImages from '@/components/Project/Three-Images'
import rkButton from '@/components/Button'

export default {
  name: 'Project',
  components: {
    rkButton
  },
  props: {
    dataProjects: {
      type: [Array],
      required: true
    }
  },
  methods: {
    renderSection (images) {
      return images.length === 1
        ? OneImage
        : images.length === 2 ? TwoImages : ThreeImages
    },
    filterProjects (tag) {
      this.$router.push({ name: 'ProjectsPage', params: { tag } })
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.projects {
  &__contact {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 565px;
      top: 0;
      background: url('~@/assets/projects/rectangle.png') no-repeat;
      background-size: cover;
      z-index: -1;
  }
    &-title {
      font-size: 1.875rem;
      font-weight: bold;
    }
  }
}
</style>
