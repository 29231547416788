<template>
  <div
    id="contact-success"
    class="contact-success u-pt-x20@md u-pt-x6"
  >
    <div class="o-container">
      <h3 class="contact-success__title u-flex u-jc-center u-color-white u-mt-x3 u-mb-x3 u-hidden u-block@sm u-text-center">
        Contact us
      </h3>
      <div class="contact-success__content o-grid u-jc-center u-relative">
        <div class="u-relative">
          <img src="@/assets/rakun-contact.png">
          <div class="contact-success__bubble u-absolute">
            <img
              class="u-absolute"
              src="@/assets/bubble.svg"
            >
            <p class="contact-success__confirmation u-relative u-text-center u-pv-x2 u-ph-x1">
              Thank you!<br>We will be in contact<br>asap…
            </p>
          </div>
        </div>
        <div class="o-grid__col u-12/12">
          <div class="contact-success__social u-flex u-jc-center u-mt-x4">
            <rk-button
              class="u-mh-x2 u-mh-x1@sm"
              title="Instagram"
            />
            <rk-button
              class="u-mh-x2 u-mh-x1@sm"
              title="Facebook"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rkButton from '@/components/Button'
export default {
  name: 'ContactSuccess',
  components: {
    rkButton
  },
  beforeRouteEnter (to, from, next) {
    from.name === 'ContactPage' ? next() : next({ name: 'ContactPage' })
  }
}
</script>

<style lang="scss" scoped>
$purple: #6545e6;
.contact-success {
  &__title  {
    font-size: 1.875rem;
    font-weight: bold;
    margin: 0;
    letter-spacing: 0;
  }
  &__content {
    @media screen and (min-width: 769px) {
      top: 2.25rem;
    }
  }
  &__bubble {
    top: -2rem;
    right: -2rem;
    width: 170px;
    height: 124px;
  }
  &__confirmation {
    color: $purple;
  }
}
</style>
