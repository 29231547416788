<template>
  <div
    id="contact"
    class="contact u-pt-x20@md u-pb-x6"
  >
    <div class="o-container">
      <h3
        v-if="isMobile"
        class="contact__title u-flex u-jc-center u-color-white u-mt-x1 u-mb-x3"
      >
        Contact us
      </h3>
      <div class="o-grid">
        <div class="o-grid__col u-6/12 u-12/12@sm u-flex u-ai-center u-jc-start u-relative">
          <h3
            v-if="!isMobile"
            class="contact__title contact__title--desktop u-absolute u-color-white"
          >
            Contact us
          </h3>
          <img src="@/assets/rakun-contact.png">
        </div>
        <div class="o-grid__col u-6/12 u-12/12@sm">
          <div id="contact-form">
            <rk-input
              id="name"
              v-model.trim="name"
              v-validate="'required'"
              :is-valid="errors.has('name')"
              class="u-8/12 u-12/12@sm"
              label="Name"
              error-message="How should we call you?"
            />
            <rk-input
              id="email"
              v-model.trim="email"
              v-validate="'required|email'"
              :is-valid="errors.collect('email').length !== 0"
              :error-message="emailErrorMessage"
              class="u-10/12 u-12/12@sm"
              label="Email"
            />
            <rk-input
              id="message"
              v-model.trim="message"
              v-validate="'required|min:40'"
              :is-valid="errors.has('message')"
              textarea
              label="Message"
              error-message="Please tell us a bit more…"
            />
            <div class="contact__bottom u-flex u-fd-col@sm u-ai-center u-jc-between u-mb-x6 u-mt-x3 u-mt-x1@sm">
              <div class="contact__consent">
                <rk-input-check
                  label="I consent to having Rakun LTD store my submitted information so they can respond to my inquiry."
                  @change="onGiveConsent"
                />
              </div>
              <rk-button
                :disabled="!isChecked"
                type="submit"
                class="u-8/12@sm u-mt-x6@sm"
                title="Send"
                @click="onSubmit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import rkInput from '@/components/Input'
import rkInputCheck from '@/components/Input-Check'
import rkButton from '@/components/Button'
import isMobile from '@/mixins/isMobile'

export default {
  name: 'ContactPage',
  components: {
    rkInput,
    rkInputCheck,
    rkButton
  },
  mixins: [isMobile],
  metaInfo: {
    title: 'Contact Us',
    meta: [
      {
        vmid: 'og:title',
        property: 'og:title',
        content: 'Contact Us - rakun.ie | Digital Design Duo'
      },
      {
        property: 'og:url',
        content: 'https://rakun.ie/contact'
      },
      {
        property: 'og:image',
        content: 'https://rakun.ie/img/thumbnail/contact.png'
      },
      {
        name: 'description',
        content: 'Do you want to work on a project with us? Contact Us'
      },
      {
        name: 'keywords',
        content:
          'team, rakun, professionals, digital, design, development, wordpress, contact'
      }
    ]
  },
  data () {
    return {
      name: '',
      email: '',
      message: '',
      isChecked: false
    }
  },
  computed: {
    emailErrorMessage () {
      return this.errors.collect('email').length > 0 && this.errors.collect('email')[0].includes('required')
        ? 'Email is missing…'
        : 'Email format appears to be wrong…'
    }
  },
  methods: {
    onGiveConsent (value) {
      this.isChecked = value
    },
    onSubmit () {
      this.$validator.validateAll().then(result => {
        return result && this.sendMessage()
      })
    },
    sendMessage () {
      let payload = {
        text: 'New Contact Form Submitted by ' + this.name + '\n Email: ' + this.email + ' \n Message: ' + this.message
      }
      payload = JSON.stringify(payload)
      axios.post('https://hooks.slack.com/services/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', payload)
        .then((response) => {
          this.$router.replace('/contact-success')
        })
        .catch((error) => {
          console.log('Something went wrong!')
        })
    }
    // autoExpandTextArea() {
    //   var textarea = document.querySelector("textarea");
    //   textarea.addEventListener("keydown", autosize);

    //   function autosize() {
    //     var el = this;
    //     setTimeout(function() {
    //       el.style.cssText = "height:auto; padding:0";
    //       // for box-sizing other than "content-box" use:
    //       // el.style.cssText = '-moz-box-sizing:content-box';
    //       el.style.cssText = "height:" + el.scrollHeight + "px";
    //     }, 0);
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.contact {
  &__title  {
    font-size: 3.125rem;
    font-weight: bold;
    margin: 0;
    letter-spacing: 0;
    @media screen and (max-width: 768px) {
      font-size: 1.875rem;
    }
    &--desktop {
      top: 0;
      width: 100%;
      text-align: center;
    }
  }
  &__consent {
    align-self: start;
  }
}
</style>
