<template>
  <label class="check u-relative u-pl-x2">
    <span class="check__title">{{ label }}</span>
    <input
      class="check__input u-absolute"
      type="checkbox"
      @change="$emit('change', $event.target.checked)"
    >
    <span class="check__mark u-absolute u-bgcolor-white" />
  </label>
</template>

<script>
export default {
  name: 'RkInputCheck',
  props: {
    label: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
$white: #ffffff;
$accent: #ffc100;
$accent-2: #ffcccc;
.check {
  display: inline-block;
  cursor: pointer;
  font-size: .75rem;
  user-select: none;
  span {
    display: inline-block;
  }
  &__title {
    position: absolute;
    width: 230px;
    top: 50%;
    transform: translate(0, -50%);
    @media screen and (max-width: 768px){
      width: 300px;
    }
    @media screen and (min-width: 769px) and (max-width: 999px){
      width: 160px;
    }
  }
  &__input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &__mark {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    border: 1px solid transparent;
    border-radius: 5px;
    height: 1rem;
    width: 1rem;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  &:hover .check__input {
    & ~ .check__mark {
      border-color: $accent-2;
    }
  }
  &__input:checked {
    & ~ .check__mark {
      border-color: $accent-2;
      &:after {
        display: block;
      }
    }
  }
  .check__mark:after {
    left: 3px;
    top: 0px;
    width: 7px;
    height: 12px;
    border: solid $accent;
    border-width: 0px 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
</style>
