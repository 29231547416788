<template>
  <div
    id="app"
    ref="app"
  >
    <transition-group
      :duration="animationDuration"
      name="fade"
      mode="out-in"
    >
      <topHeader
        v-show="loaded"
        key="header"
        :is-mobile="isMobile"
      />
      <main
        v-show="loaded"
        key="main"
      >
        <transition
          :duration="animationDuration"
          name="fade"
          mode="out-in"
        >
          <router-view :key="$route.path" />
        </transition>
      </main>
      <component
        :is="renderFooter"
        v-show="loaded"
        key="footer"
        :bgcolor="$route.name === 'Index' ? 'dark' : 'primary'"
        :class="footerClasses"
      />
      <div
        v-if="!loaded"
        key="load"
        class="loader"
      >
        ...loading...
      </div>
    </transition-group>
  </div>
</template>

<script>
import topHeader from '@/components/topHeader'
import footerSection from '@/components/Footer/Footer'
import footerMobile from '@/components/Footer/Footer-Mobile'
import isMobile from '@/mixins/isMobile'
// import chat from "@/plugins/crisp_chat";
// import hotjar from "@/plugins/hotjar";

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'rakun - Digital Design Duo',
    // all titles will be injected into this template
    titleTemplate: '%s | rakun - Digital Design Duo'
  },
  components: {
    topHeader,
    footerSection,
    footerMobile
  },
  mixins: [isMobile],
  data () {
    return {
      animationDuration: { enter: 500, leave: 500 },
      loaded: false
    }
  },
  computed: {
    renderFooter () {
      return this.isMobile ? footerMobile : footerSection
    },
    footerClasses () {
      return {
        'u-bgcolor-overlay': this.$route.path === '/about'
      }
    }
  },
  mounted () {
    // do something after mounting vue instance
    // this.$nextTick(function() {
    this.loaded = true
    // });
    // this.checkMobile()
  }
}
</script>

<style lang="scss">
#app {
  overflow: hidden;
}
.u-bgcolor-overlay {
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
