<template>
  <footer
    :class="bgcolor === 'dark' ? 'u-bgcolor-dark-footer' : ''"
    class="u-relative"
  >
    <div class="o-container">
      <div class="o-grid o-grid--middle c-footer_min-height u-pt-x4">
        <div class="o-grid__col u-6/12 u-ai-center">
          <router-link
            class="u-mh-x1 u-color-primary"
            to="/"
          >
            <b>rakun.ie</b>
          </router-link>
          <router-link
            class="u-mh-x1 u-color-primary"
            to="/projects"
          >
            <b>projects</b>
          </router-link>
          <router-link
            class="u-mh-x1 u-color-primary"
            to="/services"
          >
            <b>services</b>
          </router-link>
          <router-link
            class="u-mh-x1 u-color-primary"
            to="/about"
          >
            <b>about</b>
          </router-link>
          <router-link
            class="primary-action u-mh-x1 u-color-primary"
            to="/contact"
          >
            <b>contact</b>
          </router-link>
        </div>
        <div class="o-grid__col u-4/12">
          <div class="u-mh-x1 u-color-primary">
            <b>Rakun Ltd |  Copyright {{ year }}</b>
          </div>
        </div>
        <div class="o-grid__col u-2/12 u-text-right">
          <div class="u-mh-x1">
            <a
              class="u-color-primary"
              href="mailto:info@rakun.ie"
            ><b>info@rakun.ie</b></a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'FooterSection',
  props: {
    'bgcolor': {
      type: String,
      required: true,
      default: function () {
        return 'primary'
      }
    }
  },
  computed: {
    year () {
      return new Date().getFullYear()
    }
  }
}
</script>
<style lang="scss" scoped>
footer {
  background-image: url("~@/assets/footer-bg.png");
  background-position: center bottom;
  z-index: 1;
  &.u-bgcolor-dark-footer {
    background-color: rgba(#000, 0.35);
  }
}
.c-footer_min-height {
  min-height: 179px;
}
a {
  font-size: 1.125rem;
  text-decoration: none;
}
</style>
