<template>
  <div class="form-item u-relative u-mt-x4">
    <label
      v-if="label"
      :for="'id' in $attrs && $attrs.id"
      class="form-item__label u-absolute u-color-white"
    >{{ label }}</label>
    <transition name="fade">
      <span
        v-if="isValid"
        class="form-item__error u-absolute u-color-accent"
      >{{ errorMessage }}</span>
    </transition>
    <input
      v-if="!textarea"
      v-bind="$attrs"
      :value="value"
      :class="{ 'form-item__input--error': isValid }"
      class="form-item__input u-color-white"
      v-on="inputListeners"
    >
    <textarea
      v-else
      v-bind="$attrs"
      :class="{ 'form-item__input--error': isValid }"
      class="form-item__input form-item__input--textarea u-color-white"
      v-on="inputListeners"
    >{{ value }}</textarea>
  </div>
</template>

<script>
export default {
  name: 'RkInput',
  inheritAttrs: false,
  props: {
    label: {
      type: [String, Number]
    },
    value: {
      required: true,
      type: [String, Number]
    },
    isValid: {
      type: Boolean
    },
    errorMessage: {
      type: [String, Number]
    },
    textarea: {
      type: Boolean
    }
  },
  $_veeValidate: {
    name () {
      return this.$attrs.id
    },
    value () {
      return this.value
    }
  },
  computed: {
    inputListeners () {
      const vm = this
      return {
        ...this.$listeners,
        input (e) {
          vm.$emit('input', e.target.value)
        } }
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fadeleave-active {
  transition: all 0.3s;
  transform: translateY(0px);
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(24px);
}
.form-item {
  &__label,
  &__error {
    top: -1.5rem;
    font-size: 1.125rem;
    font-weight: bold;
  }
  &__label {
    left: 0.5rem;
  }
  &__error {
    right: 0;
  }
  &__input {
    z-index: 1;
    position: relative;
    resize: none;
    width: 100%;
    height: 3rem;
    border-radius: 0.25rem;
    padding: 0.75rem;
    font-family: inherit;
    font-size: 1rem;
    line-height: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background-color: #8061ff;
    &:focus {
      outline: 0;
    }
    &--textarea {
      height: 208px !important;
    }
  }
}
</style>
