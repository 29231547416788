<template>
  <section
    :class="classes(isDark)"
    class="project__three-images u-mt-x6 u-mb-x6"
  >
    <div class="o-container">
      <div class="o-grid">
        <div class="o-grid__col u-3/12">
          <img
            :src="require(`@/assets/projects/${project.image[0]}`)"
            class="project__three-images-image"
          >
          <rk-button
            v-if="project.buttonTitle"
            :title="project.buttonTitle"
            class="u-mt-x2"
            @click="openWindow(project.buttonLink)"
          />
        </div>
        <div class="o-grid__col u-relative u-8/12 u-push-1/12">
          <project-description
            :data="project"
            class="project__three-images-desc u-5/12 u-mb-x6 u-relative"
            @tag-click="(tagTitle) => $emit('tag-click', tagTitle)"
          />
          <img
            :src="require(`@/assets/projects/${project.image[1]}`)"
            class="project__three-images-image2 u-push-2/3 u-absolute"
          >
          <img
            :src="require(`@/assets/projects/${project.image[2]}`)"
            class="project__three-images-image3"
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import projectSections from '@/mixins/project-sections'
export default {
  name: 'ThreeImages',
  mixins: [projectSections]
}
</script>

<style lang="scss" scoped>
$purple: #4f38be;
.project__three-images {
  &-desc {
    z-index: 1;
  }
  &-image2 {
    top: -10%;
    left: 50%;
    max-height: 370px;
  }
  &--dark {
    background-color: $purple;
    padding: 150px 0 50px;
    margin-bottom: 50px;
  }
}
</style>
