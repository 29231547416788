export default {
  projects: [
    // {
    //   id: "pressidium",
    //   image: ['pressidium.png', 'pressidium-stats.png'],
    //   imageMobile: ['pressidium-mobile.png'],
    //   title: "Pressidium",
    //   text: "One of our finest projects. Pressidium is a Managed Wordpress Hosting service. A platform where small teams or larger enterprises like Fujitsu are hosting and managing their Wordpress sites. Rakun has helped Pressidium finalise the designs of it’s latest dashboard. We have also participated in developing a reliable and state-of-art tool for a very demanding market.",
    //   tags: [
    //     { title: 'development', link: '#'},
    //     { title: 'design_support', link: '#'},
    //     { title: 'ux', link: '#'}
    //   ],
    //   buttonTitle: "Visit Pressidium",
    //   buttonLink: "https://dashboard.pressidium.com",
    //   dark: false
    // },
    {
      id: 'mybodymoon',
      image: ['mybodymoon.png'],
      imageMobile: ['mybodymoon-mobile.png'],
      title: 'Mybodymoon',
      text: 'Mybodymoon provides a range of healthcare treatments in very competitive prices. They teamed up with us to together identify the needs of their clients and build an informative and value increasing website.',
      tags: [{
        title: 'development',
        link: '#'
      },
      {
        title: 'design',
        link: '#'
      }
      ],
      buttonTitle: 'Visit Mybodymoon',
      buttonLink: 'https://mybodymoon.com',
      dark: true
    },
    {
      id: 'guidedbymanual',
      image: ['guidedformanual.png', 'guidedformanual2.png', 'guidedformanual3.png'],
      imageMobile: ['guidedbymanual-mobile.png'],
      title: 'Guided for manual.co',
      text: 'Manual is every man’s guide to owning their health and happiness. A website full of tips, tools, suggestions and advice about wellbeing. The team behind Manual approached Rakun to develop the blog and support with the design.',
      tags: [{
        title: 'development',
        link: '#'
      },
      {
        title: 'design_support',
        link: '#'
      }
      ],
      buttonTitle: 'Visit Guided by Manual',
      buttonLink: 'https://www.manual.co/guided',
      dark: false
    },
    {
      id: 'fpower',
      image: ['fpower2.png', 'fpower.png'],
      imageMobile: ['fpower-mobile.png'],
      title: 'FPower',
      text: 'This is a project like no other. Supporting Non Profit organisations has been an area of passion for our co-founders Larry & Mike. Rakun and FPower started from user research and brand identity to give shape and soul to the online form of this amazing project.',
      tags: [{
        title: 'development',
        link: '#'
      },
      {
        title: 'web_design',
        link: '#'
      },
      {
        title: 'brand_identity',
        link: '#'
      }
      ],
      buttonTitle: 'Meet FPower',
      buttonLink: 'https://fpower.netlify.app/',
      dark: true
    },
    {
      id: 'krataion',
      image: ['krataion.png'],
      imageMobile: ['krataion-mobile.png'],
      title: 'Krataion',
      text: 'Krataion isn an ambitious consulting firm in Greece with clients like Google & Kleeman. We worked together to create a crisp and fresh brand along with a simple yet meaningful online experience.',
      tags: [{
        title: 'development',
        link: '#'
      },
      {
        title: 'brand_identity',
        link: '#'
      }
      ],
      buttonTitle: 'Visit Krataion',
      buttonLink: 'https://krataionconsulting.com/',
      dark: false
    },
    {
      id: 'originsfostercare',
      image: ['origins2.png', 'origins.png', 'origins3.png'],
      imageMobile: ['originsfostercare-mobile.png'],
      title: 'Origins Foster Care',
      text: 'This was Rakun’s first ever project. Building a website that empowers future foster carers. An online platform with stories, all the needed personal and legal information and ways to come in contact with the team behind Origins.',
      tags: [{
        title: 'user_experience',
        link: '#'
      },
      {
        title: 'development',
        link: '#'
      },
      {
        title: 'brand_identity',
        link: '#'
      }
      ],
      buttonTitle: '',
      buttonLink: '',
      dark: true
    }
    // {
    //   id: "brianandantony",
    //   image: ['brianandantony2.png', 'brianandantony.png', 'brianandantony3.png'],
    //   imageMobile: ['brianandantony-mobile.png', 'brianandantony2-mobile.png'],
    //   title: "Brian & Antony",
    //   text: "It looks like a game but it was a wedding site! Our dear friends were getting married and we brought together a website so their family and friends could get all the needed information for booking accomodation, flights, dietary preferences etc. The wedding theme? Super Mario & Luigi!",
    //   tags: [{
    //       title: 'user_experience',
    //       link: '#'
    //     },
    //     {
    //       title: 'development',
    //       link: '#'
    //     }
    //   ],
    //   buttonTitle: "",
    //   buttonLink: "",
    //   dark: false
    // }
  ]
}
