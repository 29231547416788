<template>
  <div
    :class="classes"
    class="project-section"
  >
    <slot name="top" />
    <project-description
      :data="{ title, text, tags }"
      @tag-click="(tagTitle) => $emit('tag-click', tagTitle)"
    />
    <rk-button
      v-if="buttonTitle"
      :title="buttonTitle"
      class="project-section__button u-mv-x2"
      @click="openWindow"
    />
    <slot name="bottom" />
  </div>
</template>

<script>
import rkButton from '@/components/Button'
import ProjectDescription from '@/components/Project/Project-Description'

export default {
  name: 'ProjectSection',
  components: {
    rkButton,
    ProjectDescription
  },
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    darkBackground: {
      type: Boolean,
      default: false
    },
    tags: {
      type: [Object, Array]
    },
    buttonTitle: {
      type: String
    },
    buttonLink: {
      type: String
    }
  },
  computed: {
    classes () {
      return {
        'project-section--dark': this.darkBackground
      }
    }
  },
  methods: {
    openWindow () {
      window.open(this.buttonLink, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
$dpurple: #4f38be;
.project-section {
  &--dark {
    background-color: $dpurple;
  }
}
</style>
